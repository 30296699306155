(function () {
    window.fbAsyncInit = function () {
        // init the FB JS SDK
        FB.init({
            appId: '600180040040976',
            xfbml: true,
            autoLogAppEvents: true,
            version: 'v3.2'
        });
    };


    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.src = "https://connect.facebook.net/es_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}());
